import axios, {AxiosError} from "axios"
import {PcaIacMappingData} from "./redux/stateTypes";
import {BACKEND_URL} from "./config";


const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});


export const getMappingsFromApi = async (): Promise<PcaIacMappingData[]> => {
  const response = await axiosInstance
      .get<PcaIacMappingData[]>(`/scope-mappings`)
      .catch((error: AxiosError) => {
        throw error;
      });
  return response.data;
};

export const fetchUserScopeDetails = async (userId : string) => {
  const json = await axiosInstance
  .get(`/user?uid=${userId}`)
  .catch((error: AxiosError) => {
    throw error;
  });
  return json.data;
};
