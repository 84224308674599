import * as React from 'react';
import MUIDataTable from "mui-datatables";

export default function MappingsTable(props) {

  const columns = [
    {
      name: "iacScopeKey",
      label: "IAC scope key"
    },
    {
      name: "iacScopeValue",
      label: "IAC scope value"
    },
    {
      name: "pcmScopeKey",
      label: "PCM scope key"
    },
    {
      name: "pcmScopeValue",
      label: "PCM scope value"
    },
    {
      name: "mappingDescription",
      label: "Mapping description"
    },
  ]

  const options = {
    filterType: 'dropdown',
    print: false,
    tableId: "DataTable1",
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 1000, 10000, 1000000],
    selectableRows: 'none',
    downloadOptions: {
      filename: 'PCM-IAC Mapping.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    }
  };

  return (
      <MUIDataTable
          title={"PCM-IAC Mapping"}
          data={props.state}
          columns={columns}
          options={options}
      />
  );
}

