import React from "react"
import {fetchUserScopeDetails} from "../../Api";
import {Scope, Unit, UserScopeDetails} from "../../redux/stateTypes";

export default function UserBasics() {
  const uidLength = 6;
  const [userScopeDetails, setUserScopeDetails] = React.useState(null as any)
  const [uid, setUid] = React.useState("");

  // is called every time the UI is re-rendered
  React.useEffect(() => {
    if (!uid || uid.length !== uidLength) {
      setUserScopeDetails(null);
      return;
    }

    fetchUserScopeDetails(uid)
    .then(res => {
      setUserScopeDetails(res)
    })
    .catch(err => {
      console.log(err)
    })
  }, [uid]);

  let userDetailsContent;
  if (!userScopeDetails) {
    userDetailsContent = <div>Please enter a user number</div>;
  } else {
    userDetailsContent =
      <div>
        <pre>{JSON.stringify(userScopeDetails, null, 2)}</pre>
        <UserBasicsView userDetails={userScopeDetails as UserScopeDetails} />
      </div>;
  }

  return (
    <div>
      <h1>User Basics</h1>
      <input
        placeholder="uXXXXX"
        value={uid}
        onChange={(e) => setUid(e.target.value)}
      />
      {userDetailsContent}
    </div>
  );
}

function UserBasicsView({ userDetails }: {userDetails: UserScopeDetails}) {
  return (
    //TODO dont show for the moment
    <div style={{display: 'none'}}>
      <ValidityView from={userDetails.validFrom} to={userDetails.validTo} />
      <p>uid: {userDetails.uid}</p>
      <p>Country: {userDetails.locationCountryIso ? userDetails.locationCountryIso : 'n/a'}</p>
      <UnitView title={'Main OrgUnit'} unit={userDetails.unit} />
      <OrgUnitsView orgUnits={userDetails.orgUnits} />
      <ScopesView title={'Specialist Scopes'} scopes={userDetails.scopes} />
      <ScopesView title={'Protection Codes'} scopes={userDetails.protectionCodes} />
    </div>
  );
}

function ValidityView({from = '?', to = '?'}) {
  return (
      <p>Valid: {from} - {to}</p>
  );
}

function UnitView({title, unit}: { title?: string, unit: Unit }) {
  let titleContent;
  if (title) {
    titleContent = <h4>{title}</h4>;
  }
  return (
    <div>
      {titleContent}
      <p>{unit?.name} - {unit?.code} - {unit?.uid}</p>
    </div>
  );
}

function OrgUnitsView({orgUnits}: { orgUnits: Unit[] }) {
  const listItems = orgUnits.map(orgUnit =>
    <li key={orgUnit.id}>
      <UnitView unit={orgUnit} />
    </li>
  );
  return (
    <div>
      <h4>OrgUnits</h4>
      { listItems.length ? <ul>{listItems}</ul> : <div>none</div>}
    </div>
  );
}

function ScopesView({title, scopes}: { title: string, scopes: Scope[] }) {
  const listItems = scopes.map(scope =>
    <li key={scope.id}>
      <div>{scope.type} - {scope.value}</div>
    </li>
  );
  return (
    <div>
      <h4>{title}</h4>
      { listItems.length ? <ul>{listItems}</ul> : <div>none</div>}
    </div>
  );
}
