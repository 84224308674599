import React from "react"
import FlatData from "./components/FlatData/FlatData";
import UserBasics from "./components/UserBasics/UserBasics";

export default function App() {
  return (
      <div className="App">
        <UserBasics />
        <hr />
        <FlatData />
      </div>
  )
}
