import React from "react"
import MappingsTable from "../MappingsTable/MappingsTable";
import {getMappingsFromApi} from "../../Api";

export default function FlatData(){

  const [mappings, setMappings] = React.useState([{
    iacScopeKey: "",
    iacScopeValue: "",
    pcmScopeKey: "",
    pcmScopeValue: "",
    mappingDescription: ""
  }])

  React.useEffect(() => {
    getMappingsFromApi()
        .then(res => {
          const newMappings = res;
          setMappings(function(){
            return newMappings.map(row => ({iacScopeKey: row.iacScope.key,
              iacScopeValue: row.iacScope.value,
              pcmScopeKey: row.pcmScope.key,
              pcmScopeValue: row.pcmScope.value,
              mappingDescription: row.desc
            }))
          })
        })
  }, [])


  return (
      <MappingsTable
          state={mappings}
      />
  )

}
